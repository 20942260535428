import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { SERVICE_FORMAT_TRANSLATED } from '../../../../common/constants/service-formart'
import { Upload } from '../components/Upload'
import { getFileNameWithOutHash } from '../../../../utils/getFileNameWithOutHash'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'

const ServicesView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()
  const [services, setServices] = useState<ServicesApiResponse | null>(null)

  useEffect(() => {
    activeLoading()
    async function loadServices(): Promise<void> {
      activeLoading()
      try {
        const responseService = await api.get<ServicesApiResponse>(apiList(id))
        const newService: {
          cover_image_file?: File
          image_description_file?: File
        } = {}
        Promise.all([
          !responseService.data.cover_image_url
            ? null
            : fetch(responseService.data.cover_image_url)
                .then(response => response.blob())
                .then(blob => {
                  const fileName = getFileNameWithOutHash(
                    responseService.data.cover_image
                  )
                  const file = new File([blob], fileName)
                  newService.cover_image_file = file
                })
                .catch(error => {
                  console.error('Erro ao baixar o arquivo:', error)
                }),
          !responseService.data.image_description_url
            ? null
            : fetch(responseService.data.image_description_url)
                .then(response => response.blob())
                .then(blob => {
                  const fileName = getFileNameWithOutHash(
                    responseService.data.image_description
                  )
                  const file = new File([blob], fileName)
                  newService.image_description_file = file
                })
                .catch(error => {
                  console.error('Erro ao baixar o arquivo:', error)
                })
        ]).then(() => {
          setServices({ ...responseService.data, ...newService })
        })

        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o serviços',
          description:
            'Houve um error ao carregar o serviços, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Serviços removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (
        err?.response?.data?.message?.includes(
          'service is used in some contract'
        )
      ) {
        addToast({
          type: 'error',
          title: 'Serviço não pode ser removido pois esta sendo usado'
        })
        return
      }
      if (err?.response?.status === 403) {
        addToast({
          type: 'error',
          title: err?.response?.data?.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Serviços não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[
            toolsViewUpdate(String(id)),
            toolsViewDelete(() => {
              handleOnClickRemoveParent()
            }),
            toolsViewCreate(),
            toolsViewList()
          ]}
        >
          <div className="form-body">
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{services?.id}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Nome
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.name}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Tipo de formulário
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.formType?.name}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Valor
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.value}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Formato de atendimento
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {SERVICE_FORMAT_TRANSLATED[services?.service_format]}
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Quantidade
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.quantity}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Parcelas sem juros
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.interest_free_installments ??
                      'Sem parcelamento sem juros'}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Categoria
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.serviceCategory?.name}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Ativo
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.active ? 'Sim' : 'Não'}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="created"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cadastrado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(services?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="updated"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Atualizado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {moment(services?.updated_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Descrição
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {services?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {(services?.cover_image_file || services?.image_description_file) && (
          <div className="container-fluid">
            <div className="card mb-5 mb-xl-10 ">
              <div className="card-body">
                <h3>Imagens</h3>
                <div className="row mb-5">
                  <div className="col-md-6">
                    {services?.cover_image_file && (
                      <Upload file={services?.cover_image_file} />
                    )}
                  </div>
                  <div className="col-md-6">
                    {services?.image_description_file && (
                      <Upload file={services?.image_description_file} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Alert
          message={`Tem certeza que deseja excluir o registro ${services?.id} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParent(Number(services?.id))
          }
          isActive={alertRemoveParent}
        />
      </>
    </div>
  )
}

export default ServicesView
