/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../components/Container'
import { HtmlEditor } from '../../../components/HtmlEditor'
import { Controller, useForm } from 'react-hook-form'
import { ContainerHtmlEditor } from './styles'
import api from '../../../services/api'
import { ENDPOINT } from '../../../common/constants/endpoint'
import { yupResolver } from '@hookform/resolvers/yup'
import { createFinalInformation } from './form-validation'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from '../../../components/Loading'
import { ResponsiveTable } from '../../../components/ReponsiveTable'
import {
  FaDownload,
  FaFilePdf,
  FaFileWord,
  FaImage,
  FaTrash
} from 'react-icons/fa'
import { handleGetExtension } from '../../../utils/getFileExtension'
import { getFileNameWithOutHash } from '../../../utils/getFileNameWithOutHash'
import { Alert } from '../../../components/Alert'
import Modal from '../../../components/Modal'
import { FormAddDocument } from './components/FormAddDocument'
import { toast } from 'react-toastify'
import { INSTRUCTION_FILE } from '../../../common/constants/instruction-file'
import moment from 'moment'
import { INPUT_MASK } from '../../../common/constants'
import { StringParam, useQueryParam } from 'use-query-params'
import { convertBas64ToString } from '../../../utils/convertToBase64'
const RENDER_ICON: Record<FileTypes, JSX.Element> = {
  pdf: <FaFilePdf size={16} color="#009EF7" />,
  word: <FaFileWord size={16} color="#009EF7" />
}

function handleDownload(file: File) {
  console.log(file)
  const blobURL = URL.createObjectURL(file)
  const a = document.createElement('a')
  a.href = blobURL
  a.download = file.name
  document.body.appendChild(a)
  a.click()
}
// function EditSaveFile({ isEditMode, onClick }: any) {
//   if (isEditMode) {
//     return (
//       <FaSave
//         style={{ cursor: 'pointer' }}
//         color="#009EF7"
//         size={16}
//         onClick={onClick}
//       />
//     )
//   }
//   return (
//     <FaEdit
//       style={{ cursor: 'pointer' }}
//       size={16}
//       onClick={onClick}
//       color="#009EF7"
//     />
//   )
// }

function ContainerEditFile({ item, isEditMode, onChange }: any) {
  if (isEditMode) {
    return (
      <input
        type="text"
        className="form-control"
        onChange={event => onChange({ event, id: item.id })}
        disabled={!onChange}
        value={
          item?.archive?.name.slice(0, item?.archive?.name.lastIndexOf('.')) ||
          ''
        }
      />
    )
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <p>{item.archive.name}</p>
      <FaDownload
        style={{ cursor: 'pointer' }}
        size={16}
        color="#009EF7"
        onClick={() => handleDownload(item.archive)}
      />
    </div>
  )
}

const FinalInformation: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [callbackUrl] = useQueryParam('callback_url', StringParam)
  // const [isEditMode, setIsEditMode] = useState<Record<string, boolean>>({})
  const [contract, setContract] = useState<ContractApiResponse | null>()
  const [excludeDocuments, setExcludeDocuments] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [openModalFolder, setOpenModalFolder] = useState(false)
  const history = useHistory()
  const reactFormHook = useForm<ContractFinalInformationFormData>({
    // @ts-ignore
    resolver: yupResolver(createFinalInformation)
  })
  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()
  const [documents, setDocuments] = useState<Documents[]>([])
  const handleLoadFinalInformation = useCallback(async () => {
    setIsLoading(true)
    try {
      const responseContract = await api.get<ContractApiResponse>(
        ENDPOINT.CONTRACT.VIEW(id)
      )
      const newDocuments: Documents[] = []
      const fetchPromises = responseContract.data.finalInformation.map(
        document =>
          fetch(document.file_url)
            .then(response => response.blob())
            .then(blob => {
              const fileName = getFileNameWithOutHash(document.file)
              const file = new File([blob], fileName)
              newDocuments.push({ archive: file, ...document })
            })
            .catch(error => {
              console.error('Erro ao baixar o arquivo:', error)
            })
      )
      Promise.all(fetchPromises).then(() => {
        setIsLoading(false)
        setDocuments(newDocuments)
      })
      setContract(responseContract.data)
      console.log(
        moment(
          responseContract.data.interview_date,
          INPUT_MASK.DATE_ISO_YEAR
        ).format(INPUT_MASK.DATE_ISO_YEAR)
      )
      reactFormHook.reset({
        final_information_text: responseContract.data.final_information_text,
        interview_date: moment(
          responseContract.data.interview_date,
          INPUT_MASK.DATE_ISO_YEAR
        ).format(INPUT_MASK.DATE_ISO_YEAR)
      })
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [id, reactFormHook])

  useEffect(() => {
    handleLoadFinalInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRenameFile = useCallback(({ event, id }: UploadOnChangeProps) => {
    if (event.target.value === '') return
    setDocuments(oldDocuments =>
      oldDocuments.map(oldFile => {
        if (oldFile.id === id) {
          if (!Number.isNaN(oldFile.id)) {
            setExcludeDocuments(oldExcludeDocuments =>
              oldExcludeDocuments.includes(Number(oldFile.id))
                ? oldExcludeDocuments
                : [...oldExcludeDocuments, Number(oldFile.id)]
            )
          }
          const findExtensionIndex = oldFile.archive.name.lastIndexOf('.')
          const extension = oldFile.archive.name.slice(
            findExtensionIndex,
            oldFile.archive.name.length
          )
          return {
            ...oldFile,
            archive: new File(
              [oldFile.archive],
              event.target.value + `${extension}`
            )
          }
        }

        return { ...oldFile }
      })
    )
  }, [])

  const onSubmit = useCallback(
    async (data: ContractFinalInformationFormData) => {
      setIsLoading(true)
      const formData = new FormData()
      data?.medias?.forEach(({ file }) => {
        if (file[0]) {
          formData.append('medias', file[0])
        }
      })
      formData.append('contract_id', id)
      formData.append('send_mail_update_final_information', '1')
      formData.append('excludeDocuments', JSON.stringify(excludeDocuments))
      Promise.all([
        await api.put(ENDPOINT.CONTRACT.UPDATE(id), {
          final_information_text: data.final_information_text,
          interview_date: data.interview_date
        }),
        await api.post(ENDPOINT.FINAL_INFORMATION.CREATE, formData)
      ])
        .then(() => {
          toast.success('Dados alterados com sucesso')
          if (callbackUrl) {
            const url = convertBas64ToString(callbackUrl)
            console.log(url)
            history.push(url)
          }
          setIsLoading(false)
        })
        .catch(error => {
          toast.error(error?.message || 'Ocorreu um erro ao atualizar os dados')
          setIsLoading(false)
        })
    },
    [callbackUrl, excludeDocuments, history, id]
  )
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  const handleRemoveFile = useCallback(
    async (file: File) => {
      setIsLoading(true)
      if (contract?.finalInformation.length) {
        const findDocument = contract?.finalInformation.find(document => {
          const fileName = getFileNameWithOutHash(document.file)
          return fileName === file.name
        })
        if (findDocument) {
          try {
            await api.delete(ENDPOINT.FINAL_INFORMATION.DELETE(findDocument.id))
            setIsLoading(false)
            toast.success('Documento removido com sucesso')
          } catch (error) {
            setIsLoading(true)
            toast.error(
              (error as ApiError)?.message || 'Documento não foi removido'
            )
          }
        }
      }
      setDocuments(oldDocuments =>
        oldDocuments.filter(document => document.archive.name !== file.name)
      )
    },
    [contract?.finalInformation]
  )
  const handleClickOnCloseDocuments = () => {
    setOpenModalFolder(false)
  }

  if (isLoading) {
    return <Loading isActive />
  }
  return (
    <>
      <Container pageTitle="Listagem" breadcrumb={[]} tools={[]}>
        <div className="mb-10">
          <div className="card-footer d-flex flex-column justify-content-end p-0 pt-4">
            <div className="d-flex mb-5 justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setOpenModalFolder(true)
                }}
              >
                Adicionar
              </button>
            </div>
            <ResponsiveTable
              headers={[
                {
                  field: 'type',
                  name: 'Tipo',
                  element: item => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {RENDER_ICON[handleGetExtension(item.archive.name)] || (
                          <FaImage size={16} />
                        )}
                      </div>
                    )
                  }
                },
                {
                  field: 'instruction',
                  name: 'Instrução',
                  element: item => {
                    return <p>{INSTRUCTION_FILE[item.instruction]}</p>
                  }
                },
                {
                  field: 'file',
                  name: 'Arquivo',
                  element: item => {
                    return (
                      <ContainerEditFile
                        // isEditMode={!!isEditMode[item.id]}
                        item={item}
                        onChange={handleRenameFile}
                      />
                    )
                  }
                },
                {
                  field: 'actions',
                  name: 'Ações',
                  element: item => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '10px'
                        }}
                      >
                        {/* <EditSaveFile
                          isEditMode={!!isEditMode[item.id]}
                          onClick={async () => {
                            setIsEditMode(oldIsEditMode => ({
                              ...oldIsEditMode,
                              [item.id]: !oldIsEditMode[item.id]
                            }))
                            const findDocument = documents.find(
                              updatedDocument => updatedDocument.id === item.id
                            )
                            console.log(findDocument.archive)
                            if (!findDocument) return
                            const formData = new FormData()
                            formData.append('medias', findDocument.archive)
                            formData.append('contract_id', id)
                            await api.delete(
                              ENDPOINT.FINAL_INFORMATION.DELETE(findDocument.id)
                            )
                            await api.post(
                              ENDPOINT.FINAL_INFORMATION.CREATE,
                              formData
                            )
                          }}
                        /> */}
                        <FaTrash
                          style={{ cursor: 'pointer' }}
                          color="#009EF7"
                          size={16}
                          onClick={() => {
                            if (contract?.finalInformation?.length) {
                              setIsActiveAlert({
                                document: item.archive,
                                isActive: true
                              })
                              return
                            }
                            handleRemoveFile(item.archive)
                          }}
                        />
                      </div>
                    )
                  }
                }
              ]}
              rows={documents.map(item => {
                return {
                  ...item,
                  file_url: item.file_url,
                  file: item.file,
                  archive: item.archive
                }
              })}
            />
          </div>
        </div>
        <form onSubmit={reactFormHook.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-3">
              <label
                htmlFor="interview_date"
                className="col-form-label fw-bold fs-6"
              >
                Data da entrevista
              </label>
              <input
                id="interview_date"
                type="date"
                className="form-control form-control-solid"
                {...reactFormHook.register('interview_date')}
              />
            </div>
          </div>
          <div className="row mt-4">
            <ContainerHtmlEditor className="col-md">
              <label
                htmlFor="description"
                className="col-form-label fw-bold fs-6"
              >
                Descrição da página
              </label>
              <Controller
                name="final_information_text"
                control={reactFormHook.control}
                render={({ field }) => {
                  return (
                    <HtmlEditor
                      defaultValue={field.value}
                      onChange={observationValue => {
                        field.value = observationValue
                        field.onChange(observationValue)
                      }}
                    />
                  )
                }}
              />
            </ContainerHtmlEditor>
          </div>
          <div className="card-footer d-flex justify-content-end pe-0">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </Container>

      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
      {openModalFolder && (
        <Modal
          onClickButtonCancel={handleClickOnCloseDocuments}
          isOpenModal={openModalFolder}
          pageTitle="Documentos"
          style={{
            padding: 0,
            stylesModal: {
              width: '1150px',
              overflow: 'auto'
            }
          }}
          Children={
            <FormAddDocument
              initialValues={{
                ...contract
              }}
              updateDocuments={data => {
                setDocuments(oldDocuments => [...oldDocuments, data])
              }}
              isOpenInModal={{
                handleOnClose: () => {
                  handleLoadFinalInformation()
                  handleClickOnCloseDocuments()
                }
              }}
            />
          }
        />
      )}
    </>
  )
}

export default FinalInformation
